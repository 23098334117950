var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.exactRoute),expression:"exactRoute"}]},[_c('v-card',[_c('v-card-title',[_vm._v(" Poptávky "),_c('v-spacer'),_c('vc-date-picker',{attrs:{"is-range":"","columns":2,"color":"blue","popover":{ visibility: 'focus' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.dateFilter;
var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"d-flex justify-center items-center"},[_c('v-text-field',_vm._g({staticClass:"rounded-0 border-right-none",staticStyle:{"max-width":"120px"},attrs:{"outlined":"","value":_vm.getLocalizedDate(_vm.dateFilter.start),"hide-details":"","readonly":"","placeholder":"Od","dense":""}},inputEvents.start)),_c('v-text-field',_vm._g({staticClass:"rounded-0 border-left-none",staticStyle:{"max-width":"120px"},attrs:{"outlined":"","value":_vm.getLocalizedDate(_vm.dateFilter.end),"hide-details":"","dense":"","readonly":"","placeholder":"Do"}},inputEvents.end))],1)]}}]),model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}}),_c('v-select',{staticClass:"rounded-0",attrs:{"deletable-chips":"","small-chips":"","dense":"","outlined":"","hide-selected":"","hide-details":"","label":"Filtr stavu","items":[{
            text: 'Závazná',
            value: 'true'
          }, {
            text: 'Nezávazná',
            value: 'false'
          }]},model:{value:(_vm.firmFilter),callback:function ($$v) {_vm.firmFilter=$$v},expression:"firmFilter"}}),_c('v-form',{staticClass:"d-flex",model:{value:(_vm.searchValid),callback:function ($$v) {_vm.searchValid=$$v},expression:"searchValid"}},[_c('v-text-field',{ref:"searchInput",staticClass:"rounded-0",attrs:{"append-icon":"mdi-magnify","label":"Vyhledat","rules":[_vm.validation.required],"single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}}),_c('v-select',{ref:"searchTypeInput",staticClass:"rounded-0",attrs:{"outlined":"","dense":"","single-line":"","hide-details":"","label":"Vyhledat podle","rules":[_vm.validation.required],"items":_vm.searchTypes},model:{value:(_vm.searchType),callback:function ($$v) {_vm.searchType=$$v},expression:"searchType"}}),_c('v-btn',{staticClass:"rounded-0",attrs:{"color":"primary","disabled":!_vm.searchValid || _vm.runningRequest},on:{"click":_vm.filterBySearch}},[_vm._v("Vyhledat")]),_c('v-btn',{staticClass:"rounded-0",attrs:{"color":"error","disabled":!_vm.searchActive || _vm.runningRequest},on:{"click":function($event){return _vm.resetSearch()}}},[_vm._v(" Zrušit vyhledávání ")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.demands,"loading":_vm.runningRequest,"server-items-length":_vm.demandsTotal,"options":_vm.paginationOptions,"footer-props":{'items-per-page-options': [10, 20, 50] }},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"item.userName",fn:function(ref){
          var item = ref.item;
return [_c('a',{staticClass:"table-link",attrs:{"href":("/admin/users/" + (item.user.id))}},[_vm._v(" "+_vm._s(item.user.email)+" ")])]}},{key:"item.hostName",fn:function(ref){
          var item = ref.item;
return [_c('a',{staticClass:"table-link",attrs:{"href":("/admin/hosts/" + (item.property.owner.id))}},[_vm._v(" "+_vm._s(item.property.owner.firstName)+" "+_vm._s(item.property.owner.lastName)+" ")])]}},{key:"item.firm",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item),"dark":""}},[_vm._v(" "+_vm._s(_vm.getFirmText(item))+" ")])]}},{key:"item.propertyName",fn:function(ref){
          var item = ref.item;
return [_c('a',{staticClass:"table-link",attrs:{"href":("/admin/properties/" + (item.property.id))}},[_vm._v(" "+_vm._s(item.property.name)+" ")])]}},{key:"item.dateFrom",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localizedDate")(item.dateFrom))+" ")]}},{key:"item.dateTo",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localizedDate")(item.dateTo))+" ")]}},{key:"item.createdAt",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localizedDate")(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.displayDemandFirmForm(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteDemand(item)}}},[_vm._v(" mdi-delete ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.redirectToDetail(item)}}},[_vm._v(" mdi-information-variant ")])]}}])})],1),_c('v-dialog',{attrs:{"width":"450"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[(_vm.editDialog)?_c('DemandFirmForm',{attrs:{"demand":_vm.editedDemand},on:{"edit-failure":function($event){_vm.editDialog = false},"edit-success":_vm.showEditSuccess}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"350"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[(_vm.deleteDialog)?_c('DeleteResource',{attrs:{"resource-type":"demands","resource":_vm.demandToDelete},on:{"delete-success":_vm.deleteDemandSuccess,"delete-failure":_vm.showDeleteFailure}}):_vm._e()],1)],1),_c('router-view',{ref:"childView"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }