<template>
  <v-card>
    <v-card-title>Změnit stav</v-card-title>
    <v-card-text class="pb-0">
      <v-select
        :items="firmStates"
        label="Stav"
        v-model="form.firm"
        class="rounded-0"
        outlined>
      </v-select>
    </v-card-text>
    <v-card-actions class="d-flex justify-end pt-0">
      <v-btn
        color="primary"
        class="rounded-0"
        :loading="runningRequest"
        @click="save"
      >
        Uložit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'DemandFirmForm',
  props: {
    demand: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      runningRequest: false,
      firmStates: [
        { text: 'Závazná', value: 1 },
        { text: 'Nezávazná', value: 0 }
      ],
      form: {
        firm: +this.demand.firm
      }
    }
  },
  methods: {
    save () {
      this.runningRequest = true
      this.$http.patch(`/demands/${this.demand.id}/firm`, this.form)
        .then(() => {
          this.$emit('edit-success')
        })
        .catch(() => {
          this.$emit('edit-failure')
        })
        .finally(() => {
          this.runningRequest = false
        })
    }
  }
}
</script>

<style scoped>

</style>
